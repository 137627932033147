import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReauth } from "src/services";

import { BODY_GET_LIST_CHARGE_STATION, ChargeStationApi, ILOCATION_PARAMS } from "./charge-station.type";
import axios from 'axios';

const handleCSParams = (data: BODY_GET_LIST_CHARGE_STATION) => {
  let params = "";
  if (data?.take === "full") {
    params
  } else if (data?.take) {
    params += "?take=" + data?.take
  } else {
    params += "?take=" + 10
  }
  if (data?.page === "full") {
    params
  } else if (data?.page) {
    params += "&page=" + data?.page
  }
  if (data?.take === "full" && data?.page === "full" && data?.companyId) {
    params += "?" + data?.companyId
  } else if (data?.companyId) {
    params += "&" + data?.companyId
  }
  return params
}

export const chargeStationApi = createApi({
  reducerPath: "chargeStationAPI",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["ChargeStation"],
  endpoints: (builder) => ({
    getListChargeStation: builder.query<any, BODY_GET_LIST_CHARGE_STATION>({
      query: (body) => {
        const params = handleCSParams(body)
        return {
          url: `${ChargeStationApi.GET_LIST_CHARGE_STATION}${params}`,
          method: "GET",
        }
      },
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        result?.data?.data
          ? [
            ...result.data.data.map(({ id }) => ({
              type: "ChargeStation" as const,
              id,
            })),
            "ChargeStation",
          ]
          : ["ChargeStation"],
    }),
    createChargeStation: builder.mutation<any, void>({
      query: (body) => ({
        url: ChargeStationApi.CREATE_CHARGE_STATION,
        method: "POST",
        body: body,
      }),
      invalidatesTags: () => ["ChargeStation"],
    }),
    updateChargeStation: builder.mutation<any, { body: any; id: string | undefined }>({
      query: ({ body, id }) => ({
        url: `${ChargeStationApi.UPDATE_CHARGE_STATION}/${id}`,
        method: "PATCH",
        body: body,
      }),
    }),
    getChargeStation: builder.query<any, string | undefined>({
      query: (id) => ({
        url: `${ChargeStationApi.GET_CHARGE_STATION}/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => [
        { type: "ChargeStation", id: result?.data?.id },
      ],
    }),
    deleteChargeStation: builder.mutation<any, string>({
      query: (id) => ({
        url: `${ChargeStationApi.DELETE_CHARGE_STATION}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: () => ["ChargeStation"],
    }),
    attachSimulator: builder.mutation<any, string>({
      query: (id) => ({
        url: `${ChargeStationApi.ATTACH_SIMULATOR}/${id}`,
        method: "PATCH",
      })
    }),
    terminateSimulator: builder.mutation<any, string>({
      query: (id) => ({
        url: `${ChargeStationApi.TERMINATE_SIMULATOR}/${id}`,
        method: "PATCH",
      })
    }),
    getAllChargeStationByLocation: builder.query<any, ILOCATION_PARAMS[]>({
      query: (items) => {
        const temp = items?.filter(item => item.check)
        let result = ''
        temp?.forEach((item, index) => {
          if (index === 0) {
            result += `?locationId[]=${item.value}`
            return
          }
          result += `&locationId[]=${item.value}`
        })
        return {
          url: `${ChargeStationApi.GET_LIST_CHARGE_STATION_LOCATION}${result}`,
          method: "GET",
        }
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useLazyGetChargeStationQuery,
  useGetChargeStationQuery,
  useGetListChargeStationQuery,
  useLazyGetListChargeStationQuery,
  useUpdateChargeStationMutation,
  useDeleteChargeStationMutation,
  useAttachSimulatorMutation,
  useTerminateSimulatorMutation,
  useCreateChargeStationMutation,
  useLazyGetAllChargeStationByLocationQuery,
} = chargeStationApi;


export const getQRCode = async (id: string) => {
  return await axios({
    method: 'GET',
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    url: `${process.env.REACT_APP_API_URL}${ChargeStationApi.QR_CODE}/${id}`,
    responseType: 'blob'
  })
    .then(response => {
      if (response) {
        const file = new Blob([response.data], { type: 'image/png' })
        return file
      }
      return Promise.reject();
    });
}

export const downloadChargeCsv = async (data: string) => {
  return await axios({
    method: 'GET',
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    url: `${process.env.REACT_APP_API_URL}${ChargeStationApi.DOWNLOAD_CSV}?${data}`,
    responseType: 'blob'
  })
    .then(response => {
      if (response) {
        const file = new Blob([response.data], { type: 'image/png' })
        return file
      }
      return Promise.reject();
    });
}