import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReauth } from "src/services";
import axios from "axios";


import { BODY_CREATE_USER, BODY_UPDATE_USER, BODY_GET_LIST_USER, UserApi } from "./user.type";

export const userAPI = createApi({
  reducerPath: "userAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getListUser: builder.query<any, BODY_GET_LIST_USER>({
      query: (body) => ({
        url: `${UserApi.GET_LIST_USER}?take=${body?.take || 10}&page=${body.page}&${body?.companyId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    deleteUser: builder.mutation<any, string>({
      query: (body) => ({
        url: `${UserApi.DELETE_USER}/${body}`,
        method: "DELETE",
      }),
    }),
    updateUser: builder.mutation<any, { body: BODY_UPDATE_USER; id: string }>({
      query: ({ body, id }) => ({
        url: `${UserApi.UPDATE_USER}/${id}`,
        method: "PATCH",
        body: body,
      }),
    }),
    createUser: builder.mutation<any, BODY_CREATE_USER>({
      query: (body) => ({
        url: UserApi.CREATE_USER,
        method: "POST",
        body: body,
      }),
    }),
    getUserDetail: builder.query<any, void>({
      query: () => ({
        url: UserApi.GET_USER,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    getUserRoles: builder.query<any, void>({
      query: () => ({
        url: UserApi.GET_ROLE,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetListUserQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useCreateUserMutation,
  useGetUserDetailQuery,
  useGetUserRolesQuery,
} = userAPI;

export const exportUserCsv = async (data: any) => {
  return await axios({
    method: 'GET',
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    url: `${process.env.REACT_APP_API_URL}${UserApi.EXPORT_USER_CSV}?${data?.companyId}`,
    responseType: 'blob',
  })
    .then(response => {
      if (response) {
        const file = new Blob([response.data], { type: 'image/png' })
        return file
      }
      return Promise.reject();
    });
}
